<template>
  <div class="program">

    <h1>{{data.Name}}</h1>
    <h3 v-if="data.Preis_als_zahl == ''">Preis auf Anfrage</h3>
    <h3 v-else-if="isNaN(data.Preis_als_zahl)">Preis auf Anfrage</h3>
    <h3 v-else-if="data.Preis_als_zahl !== 1000000">CHF {{ data.Preis_als_zahl }}</h3>
    <h3 v-else>Preis auf Anfrage</h3>
    <div class="price-image-padding"></div>

    <div class="shop-images">
      <p class="shop-description">{{data.Beschrieb}}</p>
      <div class="image-and-caption" v-for="(image, i) in images.slice(0,1)" :key="i" @click="index = i">
        <div>
          <img class="shop-image" :src="image" >
        </div>

        <button id="mehr-ansichten" @click="index = i">Mehr Ansichten</button>

      </div>

      <p class="item-id">Artikelnummer: {{data.Artikelnummer}}</p>
      <li class="tags">
        <ul class="filter item-tags" v-for="tag in data.Tags" :key="tag">
          {{tag}}
        </ul>
      </li>
      <p v-if="data.Zustand !== ''" class="item-id">Zustand: {{data.Zustand}}</p>
    </div>
    <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>


    <a class="contactbutton" :href="'mailto:' + mailToContents">Kontaktiere uns!</a>
    <button id="backbutton" @click="$router.go(-1)">
      Zurück
    </button>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {
  components: {
    VueGallerySlideshow,
  },
  name: 'Item',
  data() {
    return {
      urlstart: process.env.VUE_APP_URLSTART,
      data: "",
      key: "102beae349dca2c0fa3abf2398dba4",
      images: [],
      index: null,
      mailToContents: "",
    };
  },
  props: {
    itemId: String,
  },
  methods: {
    imageProcess() {
      for(let i in this.data.Bilder){
        // console.log(i);
        this.images.push("http://baselcitystudios.com" + this.data.Bilder[i].path)
      }

      // console.log(this.images)
    },
    createMailtoContents(){
      this.mailToContents = "sale@baselcitystudios.com?subject=Interesse%20für%20Artikel%20"+this.data.Artikelnummer + "&body=Hallo, Ich würde folgendes Gerät gerne kaufen: " + this.data.Name;
      // console.log(this.mailToContents);
    },
  },
  mounted() {

    this.axios.get(this.urlstart + "/api/collections/get/shopitems?token=" + this.key + "&filter[_id]=" + this.itemId).then((response) => {
      if(response.data.entries.slice().length === 0){
        this.axios.get(this.urlstart + "/api/collections/get/Mikrofone?token=" + this.key + "&filter[_id]=" + this.itemId).then((response) => {
          if(response.data.entries.slice().length === 0){
            this.axios.get(this.urlstart + "/api/collections/get/Sonstiges?token=" + this.key + "&filter[_id]=" + this.itemId).then((response) => {
              this.data = response.data.entries.slice()[0];
              this.imageProcess();
              this.createMailtoContents();
            }).catch(error => {
              console.log(error)
            });
          } else {
            this.data = response.data.entries.slice()[0];
            this.imageProcess();
            this.createMailtoContents();


          }

        }).catch(error => {
          console.log(error)
        });
      } else {
        this.data = response.data.entries.slice()[0];
        this.imageProcess();
        this.createMailtoContents();
      }
    }).catch(error => {
      console.log(error)
    });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

